import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #03132C;
  padding: 2.375rem 1.75rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  margin-bottom: 2.5rem;
  
  img {
    width: 13.5625rem;
    height: 13.5rem;
  }
  
  @media screen and (min-width: 768px) {
    margin-right: 2rem;
    margin-bottom: 0;

    img {
      width: 21.3125rem;
      height: 21.25rem;
    }
  }

  @media screen and (min-width: 1024px) {
    margin-right: 2rem;

    img {
      width:  23.9375rem;
      height: 23.875rem;
    }
  }
`

export const Content = styled.div`
  text-align: center;
`
export const Counter = styled.div`
  color: #D5A9FA;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 2.75rem;
  margin-bottom: 0.05rem;
`
export const Subtitle = styled.div`
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #FFFFFF;
  padding-bottom: 2.25rem;
  margin-bottom: 1rem;
  position: relative;

  hr {
    position: absolute;
    width: 13.25rem;
    bottom: 0;
    height: 2px;
    background: #D5A9FA;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    border-color: transparent;
  }
`

export const Text = styled.div`
  font-size: 1rem;
  line-height: 1.188rem;
  text-align: center;
  color: #D4D5E9;
  max-width: 21.5625rem;
  max-height: 4.125rem;
`