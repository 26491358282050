import { useState, useEffect, useCallback, useMemo } from 'react'
import {
  Wrapper,
  Image,
  Content,
  Counter,
  Subtitle,
  Text,
} from './styles'

const LS_KEY = 'counter'
const randomInc = [0, 1, 2]
const MAX_DIFF_PER_SESSION = 20

const AdditionallySection = ({
  img,
  startCounter,
  subtitle,
  text
}) => {
  const initialCounter = useMemo(() => (
    Number(localStorage.getItem(LS_KEY)) || Number(startCounter) || 0
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [])
  const [number, setNumber] = useState(initialCounter)

  const uppNumber = useCallback(() => {
    const newNumber = number + randomInc[Math.floor(Math.random() * randomInc.length)]

    if (initialCounter + MAX_DIFF_PER_SESSION >= newNumber) {
      setNumber(newNumber)
      localStorage.setItem(LS_KEY, newNumber)
    }
  }, [number, initialCounter])

  useEffect(() => {
    const interval = setInterval(uppNumber, 3000)

    return () => {
      clearInterval(interval)
    }
  }, [uppNumber])

  return (
    <Wrapper>
      <Image>
        <img src={img} alt="" />
      </Image>

      <Content>
        <Counter>
          {String(number)?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </Counter>
        <Subtitle>
          {subtitle}
          <hr/>
        </Subtitle>
        <Text>{text}</Text>
      </Content>
    </Wrapper>
  )
}

export default AdditionallySection
