
import { useEffect, useContext } from 'react'
import ReactLoading from 'react-loading'
import { useLocation } from 'react-router-dom'
import useScrollTop from './hooks/useScrollTop'
import useBodyFontSize from './hooks/useBodyFontSize'
import RootRouter from './router'
import { setUserData } from './services/firebase'
import Context from './state/Context'
import './theme/base.css'
import { LoadingPageWrapper } from './styles'

const App = () => {
  const { isLoading } = useContext(Context)
  const location = useLocation()

  useScrollTop(location.pathname)

  useBodyFontSize(window.screen.width)

  useEffect(() => {
    setUserData()
  }, [])

  return isLoading ? (
    <LoadingPageWrapper>
      <ReactLoading type="spin" color="#AC49FF" width={45} />
    </LoadingPageWrapper>
  ) : (
    <RootRouter />
  )
}

export default App
