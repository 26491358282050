import { useEffect } from 'react'

const MAX_STATIC_SCREEN_WIDTH = 1920
const BASIC_FONT_SIZE = 16

const useBodyFontSize = () => {
  useEffect(() => {
    const cb = () => {
      const screenWidth = window.screen.width

      if (MAX_STATIC_SCREEN_WIDTH < screenWidth) {
        const html = document.querySelector('html')
        html.setAttribute(
          'style',
          `font-size: ${screenWidth / MAX_STATIC_SCREEN_WIDTH * BASIC_FONT_SIZE}px`,
        )
      }
    }

    cb()

    window.addEventListener('resize', cb)

    return () => {
      window.removeEventListener('resize', cb)
    }
  }, [])
}

export default useBodyFontSize
