import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useInView } from 'react-hook-inview'
import Context from '../../../state/Context'
import { logEvent } from '../../../services/firebase'
import { getLink } from '../../../services/oneLinkGenerator'
import { ROUTES } from '../../../router/config'
import {
  Wrapper,
  HeaderTitle,
  HeaderSubtitle,
  HeaderDownloadBtn,
  HeaderImageContainer,
} from './styles'

const storeInfo = new Proxy(
  {
    ios: 'app_store',
    android: 'play_market',
    pc: 'hc_landing_2_screen',
  },
  {
    get: (target, name) => target[name] || 'hc_landing_2_screen'
  }
)

const HeaderSection = ({
  img,
  title,
  isButtonFixed,
}) => {
  const { platform } = useContext(Context)
  const history = useHistory()

  const [buttonRef, isButtonInView] = useInView()

  const onDownloadClick = () => {
    const downloadAppLink = getLink()

    logEvent('main_store_button_tap', {
      store_info: storeInfo[platform],
      platform,
    })

    if (['ios', 'android'].includes(platform)) {
      window.location.href = downloadAppLink
    } else {
      history.push(ROUTES.chooseApp)
    }
  }

  return (
    <Wrapper>
      <div>
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderSubtitle>With <span>5 000 free</span> pictures to color</HeaderSubtitle>
        <div ref={buttonRef}>
          <HeaderDownloadBtn isFixed={isButtonFixed && !isButtonInView} onClick={onDownloadClick}>
            Download Free
          </HeaderDownloadBtn>
        </div>
      </div>
      <HeaderImageContainer>
        <img src={img} alt="" />
      </HeaderImageContainer>
    </Wrapper>
  )
}

export default HeaderSection
