import { useEffect, useContext } from 'react'
import { useInView } from 'react-hook-inview'
import { ReactComponent as LikeIcon } from '../../assets/like.svg'
import { ReactComponent as MessageIcon } from '../../assets/message.svg'
import { ReactComponent as InstallIcon } from '../../assets/install.svg'
import { logEvent } from '../../services/firebase'
import Context from '../../state/Context'
import Benefit from './Benefit'
import Carousel from './Carousel'
import HeaderSection from './HeaderSection'
import AdditionallySection from './AdditionallySection'
import DownloadSection from './DownloadSection'
import FooterSection from './FooterSection'
import {
  BenefitsSection,
  SectionTitle,
  BenefitItems,
  CorouselSection,
  CorouselSectionTitle,
  CorouselSectionSubtitle,
  CorouselSectionText,
} from './styles'

const Home = () => {
  const { locales } = useContext(Context)

  const { home: page } = locales

  const [elToShowRef, elToShowInView] = useInView()
  const [elToHideRef, elToHideInView] = useInView()

  const benefits = [
    {
      icon: <LikeIcon />,
      isRating: true,
      counter: page?.second_section_item_number_1,
      name: page?.second_section_item_name_1,
      desc: page?.second_section_item_desc_1,
    },
    {
      icon: <MessageIcon />,
      counter: page?.second_section_item_number_2,
      name: page?.second_section_item_name_2,
      desc: page?.second_section_item_desc_2,
    },
    {
      icon: <InstallIcon />,
      counter: page?.second_section_item_number_3,
      name: page?.second_section_item_name_3,
      desc: page?.second_section_item_desc_3,
    },
  ]

  const sliderItems = [
    page?.third_section_img_1,
    page?.third_section_img_2,
    page?.third_section_img_3,
    page?.third_section_img_4,
    page?.third_section_img_5,
    page?.third_section_img_6,
    page?.third_section_img_7,
    page?.third_section_img_8,
  ]

  useEffect(() => {
    logEvent('hc_landing_screen')
  }, [])

  return (
    <>
      <HeaderSection
        title={page?.first_section_title}
        img={page?.first_section_img}
        isButtonFixed={(elToShowInView && !elToHideInView) || (!elToShowInView && elToHideInView)}
      />

      <BenefitsSection>
        <SectionTitle>{page?.second_section_title}</SectionTitle>
        <BenefitItems>
          {benefits.map((item) => <Benefit key={item.name} {...item} />)}
        </BenefitItems>
      </BenefitsSection>

      <div ref={elToShowRef}>
        <CorouselSection>
          <CorouselSectionTitle>{page?.third_section_title}</CorouselSectionTitle>
          <CorouselSectionSubtitle>{page?.third_section_subtitle}</CorouselSectionSubtitle>
          <CorouselSectionText>{page?.third_section_desc}</CorouselSectionText>

          <Carousel slides={sliderItems} />
        </CorouselSection>

        <AdditionallySection
          img={page?.fourth_section_img}
          startCounter={page?.fourth_section_count}
          subtitle={page?.fourth_section_subtitle}
          text={page?.fourth_section_desc}
        />
      </div>

      <div ref={elToHideRef}>
        <DownloadSection 
          title={page?.fifth_section_title}
          text={page?.fifth_section_subtitle}
        />

        <FooterSection 
          privacy={page?.sixth_section_policy}
          terms={page?.sixth_section_terms}
          copyright={page?.sixth_section_copyright}
        />
      </div>
    </>
  )
}

export default Home
