import React, {useContext} from 'react';
import Context from "../../state/Context";
import FooterSection from "../Home/FooterSection";
import './style.scss';

const PrivacyPolicyPage = () => {
    const {locales} = useContext(Context)

    const {home: page} = locales
    return (
        <div>
            <div className="sample-page__wrapper">
                <h1 className="sample-page__title">Privacy Policy</h1>

                <div className="sample-page__content">

                    <p>In addition to the terms set out in the standard “LICENSED APPLICATION END USER LICENSE
                        AGREEMENT” (can be found here <a
                            href="http://www.apple.com/legal/internet-services/itunes/appstore/dev/stdeula/"
                            target="_blank">http://www.apple.com/legal/internet-services/itunes/appstore/dev/stdeula/</a>),
                        users agree to the
                        Application collecting certain detailed user information indicated in EULA &amp; PRIVACY POLICY
                        below.</p>


                    <h2 id="table-of-contents">TABLE OF CONTENTS:</h2>

                    <ol className="headlines">
                        <li><a href="#1-introduction">Introduction</a></li>
                        <li><a href="#2-data-we-collect">Data We Collect</a></li>
                        <li><a href="#3-online-tracking-and-opt-out">Online Tracking and Opt-out</a></li>
                        <li><a href="#4-how-we-use-the-information-we-collect">How We Use The
                            Information We
                            Collect</a></li>
                        <li><a href="#5-cross-app-advertising-and-other-interest-based-advertising-technologies">Cross-app advertising and other interest-based advertising technologies</a></li>
                        <li><a href="#6-sharing-your-information">Sharing Your Information</a></li>
                        <li><a href="#7-cookies-identifiers-and-data-analysis">Cookies, Identifiers, Data Analysis</a></li>
                        <li><a href="#8-how-we-protect-your-information">How We Protect Your
                            Information</a></li>
                        <li><a href="#9-information-relating-to-children">Information Relating to Children</a></li>
                        <li><a href="#10-where-we-store-your-information">Where We Store Your
                            Information</a></li>
                        <li><a href="#11-how-long-we-store-your-information">How
                            Long We Store Your Information</a></li>
                        <li><a href="#12-data-deletion">Data Deletion</a></li>
                        <li><a href="#13-access-to-and-choices-regarding-your-information">Access To and Choices Regarding
                            Your Information</a></li>
                        <li><a href="#14-eu-consent-policy-and-personalized-experience">EU Consent Policy and Personalized Experience</a></li>
                        <li><a href="#15-сalifornia-residents-your-privacy-rights">Сalifornia Residents: Your Privacy Rights</a></li>
                    </ol>


                    <h3 id="1-introduction">1. INTRODUCTION</h3>


                    <p>This Privacy Policy applies when you use any of the Apps of this publisher and should be read
                        together with the End User License Agreement EULA. By using a Publisher’s App, you are
                        consenting to our processing of your information in ways set out in this Privacy Policy. If you
                        do not agree with it, please do not use any Publisher’s Apps. In certain cases, for example, if
                        geolocation data is processed by the relevant Publisher’s App, the Publisher’s App will prompt
                        you to give additional consent. In such a case, you can choose not to give consent to our
                        processing data related to your location but you can still use the Publisher’s App.<br></br>This
                        Privacy Policy may change so you should review it regularly. We will notify you of any
                        material changes in the way we treat your information through the Publisher’s Apps you use.
                    </p>


                    <h3 id="2-data-we-collect">2. DATA WE COLLECT</h3>


                    <p>When you use a Publisher’s App, Publisher may collect data, including information that directly
                        or indirectly identifies you. Your information may be kept in an identifiable format, or in an
                        aggregate format which means that you cannot reasonably be identified from it. The information
                        we collect about you includes:</p>


                    <p>If a Publisher’s App offers you the option to register, we will collect the information you
                        provide to us in the registration form. This may include identification data such as your name,
                        email address or other account or registration information.<br></br>We will also collect your
                        information when you use Publisher’s Apps such as when you post or share material, upload
                        data, request further services or make selections using the Publisher’s Apps if such
                        functionality is available in the Publisher’s App.<br></br>We will collect information about you
                        when you interact with us such as when you report a problem, contact or communicate with
                        us.<br></br>We may collect certain device information and electronic identifiers such as your
                        mobile device identifier provided by your mobile device operating system, your
                        mobile operating system details and the name of your mobile carrier. For example, if
                        you use the iOS platform then Advertiser IDs (also known as “IDFAs”) may be
                        collected. Other identifiers collected may include IP addresses, OpenUDID, Session
                        ID, ODIn1, iOS Vendor IDs, MAC addresses, IMEI, Android ID for Android platform, and
                        ODIN1 for OS X (together with the IDFAs, the “App Identifiers”).<br></br>Information
                        about how and when you use the Publisher’s App. This includes the number of
                        times you used the Publisher’s App, which parts of it you viewed and other
                        technical data such as country settings and timestamps.<br></br>Geolocation data,
                        which is any information that indicates the geographical position of your
                        device and which may include the time the location was recorded. Before
                        processing any precise GPS geolocation data, the Publisher’s App will
                        require you to give your consent, which you can revoke by changing the
                        privacy settings of your device.<br></br>Any of your information you provide or
                        permit to be provided to us via a social network or public forum
                        provider like Facebook when you link the Publisher’s App to your social
                        network or public forum account. This data may include your use of the
                        Publisher’s App on such public forums and/or social networks, including
                        with whom you share the Publisher’s App. For further information on how
                        and for what purpose the social network provider collects etc. your data
                        see their privacy policy.<br></br>We automatically collect certain
                        information from your device when you use the Publisher’s App
                        including information about your device, carrier and other technical
                        data.</p>


                    <h3 id="3-online-tracking-and-opt-out">3. ONLINE TRACKING AND OPT-OUT</h3>


                    <p>We are committed to providing you with meaningful choices about the information collected from
                        you on our Publisher’s Apps for third party purposes, and that is why we provide the links below
                        to the NAI “Consumer Opt-out,” DAA opt-out, and Google and other opt-outs that are only
                        applicable to participating third parties, which does not include Publisher. As stated below you
                        may also block certain App Identifiers by changing the settings of your device. However, we do
                        not currently recognize or respond to browser-initiated Do Not Track signals, as the Internet
                        industry is still working on Do Not Track standards, implementations and solutions.<br></br><a
                            href="https://support.google.com/ads/answer/2662922?hl=en">https://support.google.com/ads/answer/2662922?hl=en</a><br></br><a
                            href="http://www.networkadvertising.org/choices/">http://www.networkadvertising.org/choices/</a><br></br><a
                            href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a><br></br><a
                            href="https://info.yahoo.com/privacy/us/yahoo/">https://info.yahoo.com/privacy/us/yahoo/</a>
                    </p>


                    <p>If you visit our login page, we will set a temporary cookie to determine if your browser accepts
                        cookies. This cookie contains no personal data and is discarded when you close your browser.</p>


                    <p>When you log in, we will also set up several cookies to save your login information and your
                        screen display choices. Login cookies last for two days, and screen options cookies last for a
                        year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your
                        account, the login cookies will be removed.</p>


                    <p>If you edit or publish an article, an additional cookie will be saved in your browser. This
                        cookie includes no personal data and simply indicates the post ID of the article you just
                        edited. It expires after 1 day.</p>


                    <h3 id="4-how-we-use-the-information-we-collect">4. HOW WE USE THE INFORMATION WE COLLECT</h3>


                    <p>We may use your information we collect to:</p>


                    <p>Deliver the Publisher’s Apps to you and ensure their optimal functionality and
                        operation;<br></br>Provide
                        you with customer support;<br></br>Perform research and analysis about your use of, or interest
                        in,
                        our products, services, or content;<br></br>In accordance with applicable law, communicate with
                        you by e-mail, postal mail, telephone and/or mobile devices (including through
                        in-application advertising) about products or services that may be of interest to you
                        either from us, our parent or subsidiary companies or other third parties. If you do not
                        wish to receive in-application advertising, you can upgrade to the paid version of the
                        app.;<br></br>Develop and display content and advertising tailored to your interests and to
                        measure its effectiveness;<br></br>Verify your eligibility and deliver prizes in
                        connection with contests and sweepstakes and in order to conduct polls and
                        surveys;<br></br>Enforce our terms, conditions, and policies and to communicate with
                        you;<br></br>Manage and improve Publisher’s App. For this purpose, we may use and
                        combine your information which we collect across different Publisher’s
                        Apps used by you;<br></br>When we collect your precise GPS geolocation data
                        (subject to your consent), we do so for the purpose of providing you
                        with our location-related products and services, for example, to
                        provide you with forecast and weather alerts for your location.<br></br>When
                        you share your information with us via a social network provider
                        like Facebook, we use this to show information on your social
                        network profile (subject to your privacy setting and preferences
                        for such social network) and to allow you to share information
                        about our services with your connections or on your social
                        network profile.<br></br>When you use the Publisher’s App, we may use
                        the App Identifiers to facilitate access to the Publisher’s
                        Apps, help us recognize you across different Publisher’s
                        Apps, to enable you to use the Publisher’s Apps, to
                        personalize your experience, to increase security, measure
                        the use and effectiveness of our Publisher’s Apps and serve
                        to advertise. You can block certain App Identifiers by
                        changing the settings of your device.</p>


                    <h3 id="5-cross-app-advertising-and-other-interest-based-advertising-technologies">5. CROSS-APP
                        ADVERTISING AND OTHER INTEREST-BASED ADVERTISING TECHNOLOGIES</h3>


                    <p>As described above, we sometimes work with third parties to deliver ads, content or offers.
                        Sometimes we or these third parties do so through a mobile “SDK.” The SDK is a bit of computer
                        code that app developers can include in their apps to enable ads to be shown, data to be
                        collected, and related services to be implemented. We may use this and similar technology to
                        deliver certain advertising through mobile applications and browsers based on data associated
                        with your mobile device, and we may work with third parties who likewise embed their SDKs into
                        our apps, for third party advertising. If you’d like to opt-out from having ads tailored to you
                        in this way on your mobile device, please follow the instructions below.</p>


                    <p>If you wish to opt-out of interest-based cross-application tracking through your mobile device,
                        you can do so through your device “settings”, as follows (but please note that the below is for
                        informational purposes only and Apple, Google, or other device platforms may change the manner
                        in which their “opt-out” settings work at any time).<br></br>Please note that if you opt-out
                        through
                        these settings, you will continue to receive advertising after you opt-out, but it may not
                        be tailored to your interests. For instance, advertising may be based on your real-time or
                        “contextual” application usage or general location inferred from the IP address.</p>


                    <p>Opting Out on iOS Devices Such as iPhones, iPads or iPods<br></br>If you have an Apple device,
                        you can
                        opt-out of mobile interest-based or “cross-app” advertising by updating to iOS 6.0 or higher,
                        and setting Limit Ad Tracking to ‘ON’. On an iPhone 6 for example, you can do this by clicking
                        on Settings -&gt; General -&gt; Privacy -&gt; Advertising and toggling “Limit Ad Tracking” to
                        ‘ON’. Our systems are designed to respect your choice and not use Information to provide
                        interest-based advertising when this setting is ON.</p>


                    <p>We may sometimes work with partners to help us or help them correlate content or ads provided to
                        users across devices or browsers. For instance, a brand may wish to use the Information that we
                        collect (or data that its own partners collect) to send offers to its customers across both
                        mobile applications and browsers. We thus also provide the links below to the NAI “Consumer
                        Opt-out,” DAA opt-out, and Google opt-out that are only applicable to participating third
                        parties, which does not include IAC. You may also block certain App Identifiers by changing the
                        settings of your device. However, we do not currently recognize or respond to browser-initiated
                        Do Not Track signals, as the Internet industry has not reached a consensus as to Do Not Track
                        standards, implementations and solutions. <br/>
                        <a href="http://www.google.com/policies/privacy/partners/">http://www.google.com/policies/privacy/partners/</a>
                        <br/>
                        <a href="https://support.google.com/ads/answer/2662922?hl=en">https://support.google.com/ads/answer/2662922?hl=en</a>
                        <br/>
                        <a href="http://www.networkadvertising.org/choices/">http://www.networkadvertising.org/choices/</a>
                        <br/>
                        <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a> <br/>
                    </p>

                    <h3 id="6-sharing-your-information">6. SHARING YOUR INFORMATION</h3>


                    <p>We do not share your information with others except as indicated below:</p>


                    <p>We may share your information with our authorized service providers that perform certain services
                        on our behalf. These services may include providing customer service and marketing assistance,
                        performing business and sales analysis, supporting Publisher’s Apps functionality, and
                        supporting marketing promotions (e.g., contests and sweepstakes) surveys and other features
                        offered through Publisher’s Apps. These service providers may have limited access to your
                        information, which is needed to perform their functions but are not permitted to share or use
                        such information for any other purposes.<br></br>When you engage in promotions offered through
                        the
                        Publisher’s Apps, we may share your information with the businesses with which we partner to
                        offer you those products, services, promotions, contests and/or sweepstakes. When you elect
                        to engage in a particular merchant’s offer or program, you authorize us to provide your
                        email address and other information to that merchant.<br></br>We may also allow third-parties,
                        including our authorized service providers, parent and subsidiary companies, affiliates,
                        advertising companies, and ad networks, to display advertisements in the Publisher’s
                        Apps or use App Identifiers for the purpose of advertising and direct marketing. These
                        companies may use technologies to collect information about users who view or interact
                        with their advertisements. This information allows them to deliver targeted
                        advertisements and gauge their effectiveness.<br></br>We combine information internally at
                        Publisher across different Publisher’s Apps. This means, for example, that we may
                        combine your information from our Notepad app with information we have about you in
                        our Emoji app. This will enable us to provide more tailored and personal experiences
                        and services to you across the family of Publisher’s Apps<br></br>Other Situations. We
                        may disclose your information (i) in response to a subpoena or similar
                        investigative demand, a court order, or a request for cooperation from a law
                        enforcement or other government agency, (ii) in connection with efforts to
                        investigate, prevent, or take other action regarding illegal activity, suspected
                        fraud or other wrongdoing; (iii) to protect and defend the rights, property or
                        safety of our company, our users, our employees, or others; (iv) to comply with
                        applicable law or cooperate with law enforcement; (v) to enforce Publisher’s
                        Apps terms and conditions or other agreements or policies; and (vi) in
                        connection with a substantial corporate transaction, such as the sale of our
                        business or the Publisher’s Apps, a divestiture, merger, consolidation, or asset
                        sale or transfer, or in the unlikely event of bankruptcy. In addition, we may
                        share aggregated information we collect, under any of the above circumstances,
                        with third parties, including advisors, advertisers and investors, for the
                        purpose of conducting general business analysis or other legitimate business
                        purposes. Please note that such aggregated information will not include any
                        information which allows third parties to identity you.</p>


                    <h3 id="7-cookies-identifiers-and-data-analysis">7. COOKIES, IDENTIFIERS AND DATA ANALYSIS</h3>


                    <p>Firebase<br></br>Publisher’s uses the Google Analytics tool Firebase in order to carry out
                        analysis on
                        Information which is gathered by Publisher’s Apps. App Identifiers and other Information which
                        Publisher’s gather are provided to Google in order to enable them to carry out data analysis.
                        Any Information (including App Identifiers) which are provided to Google will become subject to
                        Google’s privacy policy which is located here: <a href="http://www.google.com/privacy.html"
                                                                          target="_blank">http://www.google.com/privacy.html</a>.
                    </p>


                    <p>For further information on how Google’s Firebase analytics tool uses data, please see the site
                        “How Google uses data when you use our partners’ sites or apps”, (located at <a
                            href="http://www.google.com/policies/privacy/partners/"
                            target="_blank">http://www.google.com/policies/privacy/partners/</a>).</p>


                    <p>Website Cookies<br></br>Separately to the Publisher’s Apps, the Publisher’s website itself uses
                        cookies. Certain information about you, (including personal information), may be collected
                        through the use of cookies when you visit and interact with our website. Some of these cookies
                        are essential for the Publisher’s website to operate and ensure that we provide the services you
                        requested. By using our services you agree to the use of certain non-essential cookies unless
                        you set your browser to reject them.</p>


                    <p>A cookie is a small text file that is stored on your computer’s hard drive or browser. We use
                        cookies and the information contained therein for a number of purposes including but not limited
                        to:</p>


                    <p>tracking the duration of visits and content accessed; and<br></br>storing frequently used user
                        information to personalize your online experience and ease the log-in process.</p>


                    <p>You may block cookies by activating the setting on your browser which allows you to refuse the
                        setting of all or some cookies. However, if you use your browser settings to block all cookies
                        (including essential cookies) you may not be able to access all or parts of our Websites. Unless
                        you have adjusted your browser setting so that it will refuse cookies, our system will issue
                        cookies as soon you visit our Site. Further information on cookies and how websites use them can
                        be obtained at&nbsp;<a href="http://www.cookiecentral.com/">http://www.cookiecentral.com</a></p>


                    <h3 id="8-how-we-protect-your-information">8. HOW WE PROTECT YOUR INFORMATION</h3>


                    <p>The security and confidentiality of your information is very important to us, so we take
                        appropriate security measures (including physical, electronic and procedural measures) to help
                        safeguard your information from unauthorized access and disclosure. Please know that, despite
                        our efforts, no security measures are perfect or impenetrable.</p>


                    <h3 id="9-information-relating-to-children">9. INFORMATION RELATING TO CHILDREN</h3>


                    <p>Some of the Publisher’s Apps are not directed to children under the age of 13. We do not
                        knowingly collect (or knowingly allow any third party to collect) information from persons under
                        the age of 13. If we become aware that information has been collected from a person under the
                        age of 13, we will delete this information and terminate the person’s account as quickly as
                        possible. If you believe that we may have information from or about a child under the age of 13,
                        please contact us and we will promptly delete that information.</p>


                    <h3 id="10-where-we-store-your-information">10. WHERE WE STORE YOUR INFORMATION</h3>


                    <p>The information that we collect from you may be transferred to, and stored at, a destination
                        outside of your country and the European Economic Area (“EEA”) and, in particular, the US. It
                        may also be processed by any service providers appointed by us who operate outside the EEA and
                        their staff, and/or our own staff based outside of the EEA. By submitting your data, you agree
                        to this transfer, storing or processing outside of the EEA. Data transferred will be treated in
                        accordance with this Privacy Policy.</p>


                    <h3 id="11-how-long-we-store-your-information">11. HOW LONG WE STORE YOUR INFORMATION</h3>


                    <p>We will keep your information for as long as it is necessary to provide you with the Publisher’s
                        Apps and for us to comply with our obligations under this Privacy Policy and at law.</p>


                    <h3 id="12-data-deletion">12. DATA DELETION</h3>


                    <p>You have the right to request that we delete any of your personal information we collected and
                        retained, subject to certain exceptions. &nbsp;Should you wish any information about you
                        deleted, you may use the in-app support feature (“Contact Us”) in our games or submit your
                        request via e-mail at&nbsp;<a href="mailto:privacy@auragames.co">privacy@auragames.co</a>. Once
                        we receive and verify your consumer request, we will delete your personal information from our
                        records. However, we may deny your deletion request if retaining the information is necessary
                        for us or our service providers under certain circumstances, which will be explained to you at
                        the time of the denial, if any.</p>


                    <h3 id="13-access-to-and-choices-regarding-your-information">13. ACCESS TO AND CHOICES REGARDING
                        YOUR INFORMATION</h3>


                    <p>You have the right to access information held about you or require it to be modified if
                        inaccurate or deleted. Your right of access can be exercised by contacting us using the details
                        set out below. Any access request may be subject to a fee to meet our costs in providing you
                        with details of the information we hold about you.</p>


                    <h3 id="14-eu-consent-policy-and-personalized-experience">14. EU Consent Policy and Personalized
                        Experience</h3>


                    <p>What type of personal data will be processed?<br></br>Depending on your Privacy Settings, our
                        partners
                        may collect and process personal data such as device identifiers, language, location data, and
                        other demographic and interest data about you to provide you with the most relevant experience
                        when using this app.You can learn more about the data we collect for our partner from the links
                        to our partners’ Privacy Policy pages below:
                        <ul>
                            <li><a rel="noreferrer noopener"
                                   href="https://www.google.com/about/company/user-consent-policy.html"
                                   target="_blank">https://www.google.com/about/company/user-consent-policy.html</a>
                            </li>
                            <li><a rel="noreferrer noopener"
                                   href="https://support.google.com/admob/answer/7666366?hl=en-GB#"
                                   target="_blank">https://support.google.com/admob/answer/7666366?hl=en-GB#</a></li>
                            <li><a rel="noreferrer noopener"
                                   href="https://www.facebook.com/business/m/one-sheeters/gdpr-developer-faqs"
                                   target="_blank">https://www.facebook.com/business/m/one-sheeters/gdpr-developer-faqs</a>
                            </li>
                            <li><a rel="noreferrer noopener" href="https://www.facebook.com/business/gdpr"
                                   target="_blank">https://www.facebook.com/business/gdpr</a></li>
                            <li><a rel="noreferrer noopener"
                                   href="https://firebase.google.com/support/privacy/#firebase_is_gdpr_ready"
                                   target="_blank">https://firebase.google.com/support/privacy/#firebase_is_gdpr_ready</a>
                            </li>
                        </ul>
                    </p>


                    <p>Why are such data processed? Who will be processing the data?<br></br>Your consent to collect
                        personal
                        data in the app personalizes your experience (ad and usage) via numerous adversing and analytics
                        partners. It will allow to remember your preferred language choice, show you more targeted ads,
                        check for fraud, share with our partners who will then sell you their services or show ads which
                        are more relevant to you, as well as it will help to faster identify bugs and problems in the
                        app if they appear.</p>


                    <p>When will processing take place? How do I withdraw my consent?<br></br>Once you agree to collect
                        your
                        personal data, the app starts processing it. Depending on partner type, user data is stored
                        hours or months to enhance your experience in the app and erased once it becomes no longer
                        necessary in relation to the purposes for which they were collected or otherwise processed. If
                        you change you mind later to withdraw your consent, you can always opt in by disabling Limit Ad
                        Tracking setting on your mobile device and then revisiting this app. If you consent us and our
                        partners personalizing your experience in a different app, we’ll collect data via that app.</p>


                    <p>How do I erase my personal data?<br></br>According to EU GDPR law, Controllers have an obligation
                        to
                        delete data that was processed on the basis of consent once that consent is withdrawn. If you
                        wish your data to be erased, please apply to support@auragames.co and include you IDFA, app’s
                        store URL&nbsp;in email subject and email body.</p>


                    <h3 id="15-сalifornia-residents-your-privacy-rights"><strong>15. Сalifornia Residents: Your Privacy
                        Rights</strong></h3>


                    <p>If you are a California resident, the California Consumer Privacy Act (CCPA) gives you the right
                        to request disclosure of information that we collect about you, to delete that information, and
                        to opt-out of the sale of your personal information (if we sell such information). This section
                        describes how to exercise those rights and our process of handling those requests. We reserve
                        the right to ask for reasonable information to verify your identity before we process your
                        request.</p>


                    <h3 id="right-to-know">Right to know</h3>


                    <p>You may submit once a year, free of charge, a verifiable request to disclose what personal
                        information we collect about you. The easiest way to submit a request is to use the in-app
                        support feature (“Contact Us”) in our games. You may also submit your request via e-mail
                        at&nbsp;<a href="mailto:privacy@auragames.co" target="_blank"
                                   rel="noreferrer noopener">privacy@auragames.co</a></p>


                    <h3 id="right-to-delete">Right to delete</h3>


                    <p>You have the right to request that we delete any of your personal information we collected and
                        retained, subject to certain exceptions. Once we receive and verify your consumer request, we
                        will delete your personal information from our records. However, we may deny your deletion
                        request if retaining the information is necessary for us or our service providers under certain
                        circumstances, which will be explained to you at the time of the denial, if any. Should you wish
                        any information about you deleted, you may use the in-app support feature (“Contact Us”) in our
                        games or submit your request via e-mail at <a
                            rel="noreferrer noopener" href="mailto:privacy@auragames.co"
                            target="_blank">privacy@auragames.co</a></p>


                    <h3 id="right-to-opt-out">Right to opt-out</h3>


                    <p>You have the right to opt-out of “sale” of your personal information, as defined by the CCPA. In
                        certain circumstances, we may share your information with our partners, who help us deliver
                        advertisements in our games tailored to your interests.</p>


                    <p>If you would prefer that your personal information is not shared with third parties in this way,
                        please, visit “Do Not Sell My Personal Information”.</p>


                    <h3 id="right-to-be-free-from-discrimination">Right to be free from discrimination</h3>


                    <p>We may not discriminate against you for exercising any of your CCPA rights. Unless permitted by
                        the CCPA, we will not: (i) deny your our Services; (ii) charge you different prices or rates for
                        our Services; (iii) provide you a different level or quality of Services; (iv) suggest that you
                        may receive a different price or rate for Services or a different level or quality of
                        Services.</p>


                    <h3 id="authorized-agents">Authorized agents</h3>


                    <p>You may exercise your CCPA rights by designating an authorized agent. If you would like to
                        designate an authorized agent to make a request on your behalf, please, be sure that the agent
                        can (i) demonstrate you have provided written permission for the agent to submit a request on
                        your behalf and (ii) provide proof of his or her own identity. We reserve the right to require
                        further reasonable information to verify the request. If the agent does not satisfy these
                        requirements, we may deny the request.</p>
                </div>
            </div>

            <FooterSection
                privacy={page?.sixth_section_policy}
                terms={page?.sixth_section_terms}
                copyright={page?.sixth_section_copyright}
            />
        </div>
    )
        ;
};

export default PrivacyPolicyPage;