import styled from "styled-components"

export const ChouseAppMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`

export const ChouseAppLogo = styled.div`
  margin-bottom: 0.875rem;

  svg {
    width: 13rem;
    height: 3.75rem;
  }

  @media screen and (min-width: 768px) {
    svg {
      width: 24.375rem;
      height: 6.875rem;
    }
  }

  @media screen and (min-width: 768px) {
    svg {
      width: 25.625rem;
      height: 7.375rem;
    }
  }
`

export const Subtitle = styled.div`
  margin-bottom: 1.4375rem;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.688rem;
  text-align: center;
  color: #222762;

  @media screen and (min-width: 768px) {
    font-size: 1.375rem;
  }
`

export const ChouseAppContent = styled.div`
  margin-bottom: 2.1875rem;

  img {
    width: 14.25rem;
    height: 15.1875rem;
  }

  @media screen and (min-width: 768px) {
    img {
      width: 18.188125rem;
      height: 19.3125rem;
    }
  }
`

export const Markets = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`

export const MarketPlaceOne = styled.button`
  svg {
    width: 10.3125rem;
    height: 3.4375rem;
  }

  @media screen and (min-width: 768px) {
    margin-right: 1.5rem;
    svg {
      width: 12rem;
      height: 4rem;
    }
  }

  @media screen and (min-width: 1024px) {
    margin-right: 2.75rem;
    svg {
      width: 16.875rem;
      height: 5rem;
    }
  }
`

export const MarketPlaceTwo = styled.button`
  svg {
    width: 11.5625rem;
    height: 3.4375rem;
  }

  @media screen and (min-width: 768px) {
    svg {
      width: 13.5rem;
      height: 3.75rem;
    }
  }

  @media screen and (min-width: 1024px) {
    svg {
      width: 16.875rem;
      height: 5rem;
    }
  }
`