import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { NextArrow, PrevArrow, SliderDotsLists, SliderItem } from './styles'

const Carousel = ({ slides }) => {
  const config = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: dots => (
      <div>
        <SliderDotsLists style={{ margin: "0px" }}>{dots}</SliderDotsLists>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1367,
        settings: {
          arrows: false,
          slidesToShow: 4,
          slidesToScroll: 4,
          centerMode: true,
          centerPadding: '10px',
        }
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          centerMode: true,
          centerPadding: '30px',
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          centerPadding: '60px',
        }
      },
      {
        breakpoint: 425,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '80px',
        }
      },
      {
        breakpoint: 375,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '60px',
        }
      },
      {
        breakpoint: 330,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '30px',
        }
      },
    ]
  }

  return (
    <Slider {...config}>
      {slides.map(item => item ? <SliderItem key={item}><img src={item} alt="" /></SliderItem> : null)}
    </Slider>
  )
}

export default Carousel
