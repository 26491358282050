import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../router/config'
import { ReactComponent as AppStoreImage } from '../../../assets/app-store.svg'
import { ReactComponent as GooglePlayImage } from '../../../assets/google-play.svg'
import Context from '../../../state/Context'
import { logEvent } from '../../../services/firebase'
import { getLink } from '../../../services/oneLinkGenerator'
import {
  Wrapper,
  Title,
  Text,
  Buttons,
  AppStoreBtn,
  GooglePlayBtn,
} from './styles'

const DownloadSection = ({
  title,
  text,
}) => {
  const { platform } = useContext(Context)
  const history = useHistory()

  const downloadAppLink = getLink()

  const redirectToDownload = () => {
    if (['ios', 'android'].includes(platform)) {
      window.location.href = downloadAppLink
    } else {
      history.push(ROUTES.chooseApp)
    }
  }

  const onAppStoreClick = () => {
    logEvent('apple_store_buttton_tap', {
      platform,
    })

    redirectToDownload()
  }

  const onPlaymarketClick = () => {
    logEvent('google_store_button_tap', {
      platform,
    })

    redirectToDownload()
  }

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
      <Buttons>
        <AppStoreBtn onClick={onAppStoreClick}>
          <AppStoreImage />
        </AppStoreBtn>

        <GooglePlayBtn onClick={onPlaymarketClick}>
          <GooglePlayImage />
        </GooglePlayBtn>
      </Buttons>

    </Wrapper>
  )
}

export default DownloadSection
