import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 1.688rem;
  padding-bottom: 2.313rem;

  @media screen and (min-width: 768px) {
    padding-top: 2.063rem;
    padding-bottom: 2.625rem;
  }

  @media screen and (min-width: 1024px) {
    padding-top: 3.375rem;
    padding-bottom: 2.875rem;
  }
`
export const Title = styled.div`
  font-weight: bold;
  text-align: center;
  color: #222762;
  font-size: 1.375rem;
  line-height: 1.688rem;
  
  @media screen and (min-width: 768px) {
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin-bottom: 0.475rem;
  }
  
  @media screen and (min-width: 1024px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
    margin-bottom: 1rem;
  }
`
export const Text = styled.div`
  margin-bottom: 1.75rem;
  text-align: center;
`
export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`
export const AppStoreBtn = styled.button`
  margin-bottom: 1.313rem;

  svg {
    width: 11.625rem;
    height: 3.4375rem;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 1.156rem;

    svg {
      width: 10.5625rem;
      height: 3.125rem;
    }
  }

  @media screen and (min-width: 1024px) {
    margin-right: 1.875rem;

    svg {
      width: 16.875rem;
      height: 5rem;
    }
  }
`
export const GooglePlayBtn = styled.button`
  svg {
    width: 11.625rem;
    height: 3.4375rem;
  }

  @media screen and (min-width: 768px) {
    svg {
      width: 10.5625rem;
      height: 3.125rem;
    }
  }
  @media screen and (min-width: 1024px) {
    svg {
      width: 16.875rem;
      height: 5rem;
    }
  }
`