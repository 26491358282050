import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import Home from '../pages/Home'
import ChooseApp from '../pages/ChooseApp'
import { ROUTES } from './config'
import TermsOfUsePage from "../pages/TermsOfUse";
import PrivacyPolicyPage from "../pages/PrivacyPolicy";

const RootRouter = () => {
  return (
    <Switch>
      <Route path={ROUTES.home} exact component={Home} />
      <Route path={ROUTES.chooseApp} exact component={ChooseApp} />
      <Route path={ROUTES.termsOfUse} exact component={TermsOfUsePage} />
      <Route path={ROUTES.privacyPolicy} exact component={PrivacyPolicyPage} />
      <Route path="*" component={() => <Redirect to={ROUTES.home} />} />
    </Switch>
  )
}

export default RootRouter
