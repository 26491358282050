import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-top: 2.5rem;
  height: 100vh;
  
  background-color: #03132C;
  color: #FFFFFF;

  @media only screen and (max-height: 560px) {
    min-height: 40rem;
  }

  @media screen and (min-width: 768px) {
    padding: 0 4.375rem;
    padding-top: 2.5rem;
    min-height: 1024px;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5.625rem 0 9rem;
  }
`

export const HeaderImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22.375rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (min-width: 768px) {
    height: 40rem;
  }

  @media screen and (min-width: 1024px) {
    height: 59.625rem;
    max-height: 100%;
  }
`

export const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 110%;
  margin-bottom: 0.438rem;
  text-align: center;
  max-width: 41.625rem;
  padding: 0 1.625rem;

  @media screen and (min-width: 768px) {
    font-size: 3.438rem;
  }

  @media screen and (min-width: 1024px) {
    text-align: start;
    font-size: 3.75rem;
    margin-bottom: 0.313rem;
    padding: 0;
  }
`

export const HeaderSubtitle = styled.div`
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.523rem;
  font-weight: 400;
  margin-bottom: 1.875rem;
  padding: 0 1.625rem;

  span {
    font-weight: 700;
    color: #AC49FF;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.5rem;

    span {
      line-height: 1.827rem;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 0;
    text-align: start;
    margin-bottom: 8.125rem;
  }
`
const opacityAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1; }
`

const blickAnimation = keyframes`
  0% { left: -25% }
  25% { left: -20% }
  75% { left: 110% }
  100% { left: 120% }
`

export const HeaderDownloadBtn = styled.button`
  width: 14rem;
  height: 3.875rem;
  background: #AC49FF;
  border-radius: 3rem;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #FFFFFF;
  position: ${props => props.isFixed ? 'fixed' : 'absolute'};
  bottom: 4.5rem;
  left: 50%;
  transform: translate(-50%, 0);
  animation-name: ${ props => props.isFixed ? opacityAnimation : ''};
  animation-duration: 0.7s;
  opacity: 1;
  overflow: hidden;
  z-index: 1;

  :after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    background: linear-gradient(-77deg, rgba(232, 204, 255, 0.12) 9.3%, rgba(213, 169, 250, 0.14) 55.74%, rgba(213, 169, 250, 0) 88.84%);
    mix-blend-mode: screen;
    filter: blur(2px);
    width: 2.541rem;
    transform: skewX(-45deg);
    transition: all 1s ease;
    animation-name: ${ blickAnimation };
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }

  :hover {
    background: #9227EC;
  }

  @media only screen and (max-height: 568px) {
    bottom: 0;
    transform: translate(-50%, -50%);
  }

  @media screen and (min-width: 768px) {
    width: 18rem;
    height: 5rem;
    bottom: 6.6rem;
  }

  @media screen and (min-width: 1024px) {
    position: ${props => props.isFixed ? 'fixed' : 'relative'};
    left: ${props => props.isFixed ? '50%' : '0'};
    transform: ${props => props.isFixed ? 'translateX(-50%)' : 'translateX(0)'};
    bottom: ${props => props.isFixed ? '4.5rem' : 'auto'};
  }
`
