import { ReactComponent as StarsIcon } from '../../../assets/stars.svg'
import {
  Wrapper,
  Icon,
  IconWrap,
  Counter,
  Name,
  Desc,
  ContainerInfo,
} from './styles'

const Benefit = (props) => {
  const {
    icon,
    isRating,
    counter,
    name,
    desc,
  } = props

  return (
    <Wrapper>
      <Icon>{icon}</Icon>
      <IconWrap>{isRating && <StarsIcon />}</IconWrap>
      <ContainerInfo>
        <Counter>{counter}</Counter>
        <Name>{name}</Name>
      </ContainerInfo>
      <Desc>{desc}</Desc>
    </Wrapper>
  )
}

export default Benefit
