import { useState, useMemo, useEffect } from 'react'
import DeviceDetector from 'device-detector-js'
import fetchData from '../services/fetchData'
import Context from './Context'

const getPlatform = () => {
  const deviceDetector = new DeviceDetector()
  const { device, os } = deviceDetector.parse(navigator.userAgent)

  if (['iOS', 'Mac'].includes(os.name)) {
    return 'ios'
  }

  if (os.name === 'Android') {
    return 'android'
  }

  if (device.type === 'desktop') {
    return 'pc'
  }

  return undefined
}

const Provider = ({ children }) => {
  const [locales, setLocales] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const platform = useMemo(getPlatform, [])

  useEffect(() => {
    const runAsync = async () => {
      try {
        const data = await fetchData()
        setLocales(data)
      } catch (err) {
        alert('Something went wrong')
      }
      setIsLoading(false)
    }

    runAsync()
  }, [])

  const value = useMemo(() => ({
    locales,
    isLoading,
    platform,
  }), [locales, isLoading, platform])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default Provider
