import { useEffect } from 'react'

const useScrollTop = (dependency) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
  }, [dependency])
}

export default useScrollTop
