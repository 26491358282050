const BASE_URL = 'https://landing.happycanvas.app/spreadsheets/'
const pageLocales = [
  { title: 'home', file: 'home.csv' },
  { title: 'chooseApp', file: 'choose_app.csv' },
]

const fetchAndMap = async (title, url) => {
  const data = await fetch(url)

  const rawCsv = await data.text()

  const csvData = rawCsv.split('\r\n')

  const parsedCsvData = csvData.reduce((acc, item) => {
    const [key, value] = item.split(/,(.+)/, 2)

    if (key && value) {
      acc[key] = value.replace(/,+$/, '')
    }

    return acc
  }, {})

  return { [title]: parsedCsvData }
}

const fetchData = async () => {
  const locales = await Promise.all(
    pageLocales.map(({ title, file }) => fetchAndMap(title, `${BASE_URL}${file}`))
  )

  return locales.reduce((acc, item) => {
    const [key, value] = Object.entries(item)[0]
    acc[key] = value
    return acc
  }, {})
}

export default fetchData
