import { useContext, useEffect, useRef } from 'react'
import { ReactComponent as InstagramIcon } from '../../../assets/instagram.svg'
import { ReactComponent as FbIcon } from '../../../assets/fb.svg'
import { ReactComponent as MailIcon } from '../../../assets/primary-fill-mail.svg'
import Context from '../../../state/Context'
import { logEvent } from '../../../services/firebase'
import {
  INSTAGRAM_LINK,
  FACEBOOK_LINK,
  PRIVACY_POLICY_LINK,
  TERMS_OF_USE_LINK,
} from '../../../config'
import {
  SectionWrapper,
  SectionContainer,
  Wraper,
  FollowUs,
  Social,
  Instagram,
  FaceBooke,
  DocPrivacy,
  Support,
  Copytight,
} from './styles'
import { useLocation } from 'react-router-dom'

const FooterSection = ({
  privacy,
  terms,
  copyright,
}) => {
  const { platform } = useContext(Context)
  const { hash: url }  = useLocation()
  const supportSection = useRef()

  const isSupportURL = () => {
    return url === '#support';
  }

  const toBottom = () => {
      if(supportSection.current){
        supportSection.current.scrollIntoView()
      }
  }

  const onSupportClick = () => {
    logEvent('support_button_tap', {
      platform,
    })

    window.open('mailto: support@auragames.co', '_blank')
  }

  const onTermsClick = () => {
    logEvent('terms_button_tap')

    window.open(TERMS_OF_USE_LINK, '_blank')
  }

  const onPolicyClick = () => {
    logEvent('privacy_button_tap')

    window.open(PRIVACY_POLICY_LINK, '_blank')
  }

  const onInstagramClick = () => {
    logEvent('instagram_button_tap')

    window.open(INSTAGRAM_LINK, '_blank')
  }

  const onFacebookClick = () => {
    logEvent('facebook_button_tap')

    window.open(FACEBOOK_LINK, '_blank')
  }

  const toSupportSection = () => {
    if(isSupportURL()){
      if (window.history.scrollRestoration) {
        window.history.scrollRestoration = 'manual';
        console.log("SCROLLRESTORATION");
        toBottom();
      } else {
        setTimeout(()=> {
          toBottom();
        }, 800)
      }

    }
  }

  useEffect(() => {
    toSupportSection();
  }, [])

  return (
    <SectionWrapper id="support" ref={supportSection}>
      <SectionContainer>
        <Wraper>
          <FollowUs>
            <span>Follow us</span>
            <Social>
              <Instagram onClick={onInstagramClick}>
                <InstagramIcon />
              </Instagram>

              <FaceBooke onClick={onFacebookClick}>
                <FbIcon />
              </FaceBooke>
            </Social>
          </FollowUs>

          <DocPrivacy>
            <a onClick={onPolicyClick}>{privacy}</a>
            <a onClick={onTermsClick}>{terms}</a>
          </DocPrivacy>
        </Wraper>

        <Support>
          <button onClick={onSupportClick}>
            <MailIcon />
            <span>Support</span>
          </button>
        </Support>

        <Copytight>{copyright}</Copytight>
      </SectionContainer>
    </SectionWrapper>
  )
}

export default FooterSection
