import { APPS_FLYER_ONELINK } from '../../config'
import { initOneLinkScript } from './lib'

const mediaSource = {keys: ["utm_source", "pid"], defaultValue: "organic"};
const campaign = {keys: ["utm_campaign", "c"], defaultValue: "organic"};
const adId = {paramKey: "ad_id", keys: ["ad_id"]};
const adName = {paramKey: "ad_name", keys: ["ad_name"]};
const adset = {paramKey: "adset", keys: ["adset"]};
const adsetId = {paramKey: "adset_id", keys: ["adset_id"]};
const campaignId = {paramKey: "campaign_id", keys: ["campaign_id"]};
const fbclid = {paramKey: "fbclid", keys: ["fbclid"]};

initOneLinkScript();

export const getLink = () => {
  const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
    oneLinkURL: APPS_FLYER_ONELINK,
    afParameters:{
      mediaSource,
      campaign,
      afCustom: [
        adId,
        adName,
        adset,
        adsetId,
        campaignId,
        fbclid,
      ]
    }
  })

  if(result){
    return result.clickURL
  }
}
