import styled from 'styled-components'

export const SliderItem = styled.div`
  outline: none;

  img {
    max-width: 16rem;
    width: 100%;
    padding: 0.45rem;
    margin: 0 auto;
    margin-bottom: 0.4rem;

    @media screen and (min-width: 768px) {
      max-width: 19rem;
    }

    @media screen and (min-width: 1024px) {
      max-width: 22.5rem;
    }
  }
`

export const SliderDotsLists = styled.ul`
  li {
    margin: 0;
    button:before {
      font-size: 0.375rem;
      color: #AFB3E0;
      opacity: 1;
    }
    
    @media screen and (min-width: 768px) {
      button:before {
        font-size: 0.5rem;
      }
    }
    
    @media screen and (min-width: 1024px) {
      margin: 0.5rem;

      button:before {
        font-size: 0.563rem;
      }
    }
  }

  li.slick-active {
    button:before {
      color: #222762;
      opacity: 1;
    }
  }
`

const Arrow = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 3.125rem;
  height: 3.125rem;
  z-index: 2;

  ::before {
    font-size: 1.5rem
  }
`

export const NextArrow = styled(Arrow)`
  right: 0.5rem;
`

export const PrevArrow = styled(Arrow)`
  left: 0.5rem;
`
