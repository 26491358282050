import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.313rem;
  margin-left: 0.969rem;
  margin-right: 0.969rem;
  
  @media screen and (min-width: 768px) {
    width: 13.75rem;
  }
  
  @media screen and (min-width: 1024px) {
    width: 18.75rem;
  }
`

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.3rem;

  svg {
    width: 100%;
    height: 100%;
  }
`


export const Icon = styled.div`
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F8FAFF;
  border-radius: 50%;
  margin-bottom: 0.447rem;

  svg {
    width: 2.375rem;
    height: 2.375rem;
  }

  @media screen and (min-width: 768px) {
    width: 5rem;
    height: 5rem;

    svg {
      width: 2.921rem;
      height: 2.921rem;
    }
  }

  @media screen and (min-width: 1024px) {
    width: 6.25rem;
    height: 6.25rem;

    svg {
      width: 3.938rem;
      height: 3.938rem;
    }
  }
`

export const ContainerInfo = styled.div`
  display: flex;
  margin-bottom: 0.447rem;
  margin-top: 0.447rem;
`

export const Counter = styled.div`
  font-weight: 700;
  margin-right: .8rem;
  font-size: 1.786rem;
  line-height: 2.176rem;

  @media screen and (min-width: 768px) {
    font-size: 2.75rem;
    line-height: 3.349rem;
  }

  @media screen and (min-width: 1024px) {
    font-size: 3rem;
    line-height: 3.688rem;
  }
`

export const Name = styled.div`
  font-size: 1.325rem;
  line-height: 1.979rem;
  padding-top: 0.2rem;
  
  @media screen and (min-width: 768px) {
    padding-top: 1rem;
    font-size: 1.5rem;
    line-height: 1.827rem;
  }

  @media screen and (min-width: 1024px) {
    padding-top: 1rem;
    font-size: 1.75rem;
    line-height: 2.125rem;
  }
`

export const Desc = styled.div`
  font-size: 1rem;
  line-height: 1.188rem;
  text-align: center;
  color: #5F5F5F;
`
