import styled from "styled-components";

export const SectionWrapper = styled.div`
  background-color: #E9EEFA;
  font-size: 0.875rem;
  line-height: 1.063rem;

  @media screen and (min-width: 768px) {
    font-size: 1rem;
    line-height: 1.188rem;
  }

  @media screen and (min-width: 1024px) {
    font-size: 1rem;
    line-height: 1.188rem;
  }
`

export const SectionContainer = styled.div`
  max-width: 1288px;
  margin: 0 auto;
  padding: 1.313rem 1.063rem;
  
  @media screen and (min-width: 768px) {
    padding: 1.25rem 1.5rem;
  }
  @media screen and (min-width: 1024px) {
    padding: 1.063rem 1.15rem;
  }
`
export const Wraper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  @media screen and (min-width: 768px) {
    margin-bottom: -2rem;
  }
`

export const FollowUs = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 0.45rem;
    color: #343434;
  }
`
export const Social = styled.div`
  display: flex;
`
export const Instagram = styled.a`
  width: 2rem;
  margin-right: 0.475rem;
  svg {
    width: 100%;
    height: 100%;
  }
`
export const FaceBooke = styled.a`
  width: 2rem;

  svg {
    width: 100%;
    height: 100%;
  }
`

export const DocPrivacy = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 0.35rem;
  
  a {
    text-decoration-line: underline;
    color: rgba(95, 95, 95, 1);
    margin-bottom: 0.45rem;
  }

  a:hover {
    color: #343434;
  }
`

export const Support = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  button {
    width: 11.3125rem;
    height: 2.625rem;
    padding: 0.625rem 1.5625rem;
    border-radius: 0.3125rem;
    background: #222762;
    color: #ffffff;

    span {
      margin-left: 1rem;
      font-size: 1.125rem;
      line-height: 1.375rem;
      text-align: center;
      color: #FFFFFF;
    }
  }

  svg {
    width: 2rem;
    height: 1.1rem;
  }
`

export const Copytight = styled.div`
  color: rgba(95, 95, 95, 1);
  display: flex;
  justify-content: center;
  text-decoration: none;
`