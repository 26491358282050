import React, {useContext} from 'react';
import Context from "../../state/Context";
import FooterSection from "../Home/FooterSection";

const TermsOfUsePage = () => {
    const {locales} = useContext(Context)

    const {home: page} = locales
    return (
        <div>
            <div className="sample-page__wrapper">
                <h1 className="sample-page__title">Terms of Use</h1>

                <div className="sample-page__content">

                    <h2 id="table-of-contents"><strong>TABLE OF CONTENTS:</strong></h2>

                    <ol type="A" className="headlines">
                        <li><a href="#a-agreement">Agreement</a></li>
                        <li><a href="#b-age-requirement">Age Requirement</a></li>
                        <li><a href="#c-grant-of-license">License to Use Publisher’s Apps</a></li>
                        <li><a href="#d-description-of-other-rights-and-limitations">Grant of Rights</a></li>
                        <li><a href="#e-publisher-s-app-functionality">Publisher’s App Functionality</a></li>
                        <li><a href="#f-third-party-partners">Third-Party Partners</a></li>
                        <li><a href="#g-security">Security</a></li>
                        <li><a href="#h-registration-passwords">Registration and Passwords</a></li>
                        <li><a href="#i-uninstall-removal-of-an-publisher-s-app">Uninstall/Removal</a></li>
                        <li><a href="#j-consent-to-use-of-data">Consent to Use of Data</a></li>
                        <li><a href="#k-intellectual-property">Intellectual Property</a></li>
                        <li><a href="#l-copyright-submissions">Copyright/Submissions</a></li>
                        <li><a href="#m-termination">Termination</a></li>
                        <li><a href="#n-disclaimer-of-warranty">Disclaimer of Warranty</a></li>
                        <li><a href="#o-limitation-of-liability">Limitation of Liability</a></li>
                        <li><a href="#p-indemnification">Indemnification</a></li>
                        <li><a href="#q-export-controls">Export Controls</a></li>
                        <li><a href="#r-notice-to-us-government-end-users">Government End User Notice</a></li>
                        <li><a href="#s-jurisdictional-issues-and-other-miscellaneous-terms">Jurisdictional Issues and Misc.</a></li>
                        <li><a href="#t-supplemental-terms">Supplemental Terms</a></li>
                    </ol>

                    <h3 id="a-agreement">A. AGREEMENT:</h3>

                    <p>A. This End-User License Agreement (“EULA”) is a legal agreement between you and Publisher (“we”,
                        “us” or “our”) which governs your use of the Publisher’s Apps. By installing or otherwise using
                        the Publisher’s Apps, you: (a) agree to be bound by the terms and conditions of this EULA, (b)
                        you represent and warrant that you own or control the mobile device in which the Publisher’s App
                        will be installed, and (c) you represent and warrant that you have the right, authority and
                        capacity to enter into this EULA and to abide by all its terms and conditions, just as if you
                        had signed it. The terms and conditions of this EULA also apply to any Publisher’s App updates,
                        supplements, and services that are not provided under a separate license or other agreement with
                        us. If you do not agree to the terms and conditions of this EULA, do not install or use any
                        Publisher’s App. We may amend these terms and conditions from time to time. If the changes
                        include material changes that affect your rights or obligations, we will notify you of the
                        changes by reasonable means. You acknowledge that an in-app message which notifies you of such
                        changes when you open up the Publisher’s App shall constitute reasonable means. Your continued
                        use of the Publisher’s Apps after we post any amendments to this EULA will signify your
                        acceptance of such amendments. If you do not agree with any amendment, you must discontinue
                        using the Publisher’s Apps. Do not use the Publisher’s Apps until your questions and concerns
                        have been answered in this document to your satisfaction and you agree to abide by the
                        EULA.<br></br><strong>NOTICE
                            TO CONSUMERS:</strong>&nbsp;Depending on the laws of the jurisdiction where you live, you
                        may have certain rights that cannot be waived through this EULA and that are in addition to
                        the terms of this EULA, and certain provisions of this EULA may be unenforceable as to you.
                        To the extent that any term or condition of this EULA is unenforceable, the remainder of the
                        EULA shall remain in full force and effect.<br></br>Use of the Publisher’s Apps is subject to
                        our
                        Privacy Policy, which is hereby incorporated into this EULA by reference. This EULA also
                        includes any additional payment terms and other requirements set forth on the download
                        or purchase page through which you purchase or download the Publisher’s Apps. The
                        Publisher’s Apps may be available through marketplaces that distribute mobile
                        applications and that may have additional terms, conditions and usage rules that govern
                        your use of the Publisher’s Apps if you download or install the Publisher’s Apps through
                        such marketplaces.</p>


                    <h3 id="b-age-requirement">B. AGE REQUIREMENT:</h3>


                    <p>You must be 13 years of age or older to install or to use the Publisher’s Apps. If you are at
                        least 13 but not yet 18 years of age, please have your parent or legal guardian review this EULA
                        with you, discuss any questions you might have, and install the Publisher’s Apps for
                        you.<br></br><strong>NOTICE TO PARENTS AND GUARDIANS:</strong>&nbsp;By granting your child
                        permission
                        to download and access an Publisher’s App, you agree to the terms and conditions of this
                        EULA on behalf of your child. You are responsible for exercising supervision over your
                        children’s online activities. If you do not agree to this EULA, do not let your child use
                        the Publisher’s Apps or associated features.</p>


                    <h3 id="c-grant-of-license">C. GRANT OF LICENSE:</h3>


                    <p>Subject to your compliance with the terms and conditions of this EULA, Publisher grants you a
                        limited, non-exclusive, revocable, non-sublicensable, non-transferable license, to access,
                        download and install the most current generally available version of the Publisher’s Apps on a
                        single, authorized mobile device that you own or control solely for your lawful, personal, and
                        non-commercial entertainment use.</p>


                    <h3 id="d-description-of-other-rights-and-limitations">D. DESCRIPTION OF OTHER RIGHTS AND
                        LIMITATIONS:</h3>


                    <p><strong>1. Restricted Use.</strong>&nbsp;You may not rent, sell, lease, sublicense, distribute,
                        assign, copy (other than a single copy for your own backup purposes), or in any way transfer or
                        grant any rights to the Publisher’s Apps or use the Publisher’s Apps for the benefit of any
                        third party. Unless expressly authorized by Publisher or permitted under the applicable mobile
                        platform terms, you are prohibited from making the Publisher’s Apps available over a network
                        where it could be downloaded or used by multiple users. You agree that you will not use any
                        robot, spider, other automatic or manual device or process to interfere or attempt to interfere
                        with the proper working of the Publisher’s Apps, except to remove Publisher’s Apps from a mobile
                        device which you own or control. You may not violate or attempt to violate the security of our
                        services. You may not modify, reverse-engineer, decompile, disassemble, or otherwise tamper with
                        any Publisher’s Apps, or attempt to do so for any reason or by any means. You may not access,
                        create or modify the source code of any Publisher’s Apps in any way. You do not have the right
                        to and may not create derivative works of any Publisher’s Apps or any portions thereof. All
                        modifications or enhancements to the Publisher’s Apps remain the sole property of
                        Publisher.<br></br><strong>2.
                            Publisher’s Apps Updates.</strong>&nbsp;We reserve the right to add or remove features or
                        functions to existing Publisher’s Apps. When installed on your mobile device, the
                        Publisher’s Apps periodically communicate with our servers. We may require the updating of
                        the Publisher’s Apps on your mobile device when we release a new version of the Publisher’s
                        Apps, or when we make new features available. This update may occur automatically or upon
                        prior notice to you, and may occur all at once or over multiple sessions. You understand
                        that we may require your review and acceptance of our then-current EULA before you will be
                        permitted to use any subsequent versions of the Publisher’s Apps. You acknowledge and agree
                        that any obligation we may have to support previous versions of the Publisher’s Apps may be
                        ended upon the availability of updates, supplements or subsequent versions of the
                        Publisher’s Apps. You acknowledge and agree that we have no obligation to make available to
                        you any updates, supplements or subsequent versions of the Publisher’s Apps.<br></br><strong>3.
                            Access.</strong>&nbsp;You must provide at your own expense the equipment, Internet
                        connections, devices and service plans to access and use the Publisher’s Apps. If you
                        access an Publisher’s App through a mobile network, your network or roaming provider’s
                        messaging, data and other rates and fees may apply. You are solely responsible for any
                        costs you incur to access the Publisher’s Apps from your device. Downloading, installing
                        or using certain Publisher’s Apps may be prohibited or restricted by your network
                        provider and not all Publisher’s Apps may work with your network provider or device.
                        Publisher makes no representation that the Publisher’s Apps can be accessed on all
                        devices or wireless service plans. Publisher makes no representation that the
                        Publisher’s Apps are available in all languages or that the Publisher’s Apps are
                        appropriate or available for use in any particular location.<br></br><strong>4. In-App
                            Purchases &amp; Cancellation Rights.</strong>&nbsp;Certain Publisher’s Apps will
                        allow you to make an in-application purchase. Payment for such purchases may be
                        processed third parties who act on our behalf or directly by the mobile platform
                        owner (e.g. Apple). By confirming the purchase , you:</p>


                    <ul>
                        <li>(a) agree that we will supply you the purchased service or item immediately after you have
                            confirmed the purchase in the Publisher’s App;
                        </li>
                        <li>(b) if you reside in the European Union, you acknowledge that you will therefore no longer
                            have the right to cancel under the EU’s Consumer Rights Directive (as implemented by the law
                            of the country where you are located) once we start to supply the service or item; Please
                            review the mobile platform owner’s terms in this regard before purchase. This may also apply
                            to subscriptions and in-app purchases. You can find further information on cancelling orders
                            and any associated refunds on the website of the third party re-seller from whom you
                            purchased the app.
                        </li>
                        <li>and&nbsp;(c) acknowledge that, to the extent permitted by law, all sales are final and that
                            Publisher is not required to provide a refund for any reason.
                        </li>
                    </ul>


                    <p><strong>5. Subscription Services.</strong></p>


                    <ul>
                        <li>Certain Publisher on the Apple App Store will allow you to obtain the benefit of the
                            application on a subscription basis. Payment for such a subscription (which may be for
                            example daily, weekly, monthly, tri-monthly or yearly) may be processed in the application,
                            by third parties who act on our behalf or directly by the mobile platform owner (e.g.
                            Apple).
                        </li>
                        <li>The subscription automatically renews for the same price and duration period as the original
                            weekly/monthly/yearly plan unless auto-renew is turned off at least 24-hours before the end
                            of the current period.
                        </li>
                        <li>The payment will be charged to your iTunes Account when you confirm the purchase.</li>
                        <li>Free trial subscriptions may be cancelled at any point up to 24 hours before the expiry of
                            the free trial (Apple). See below in paragraph 6 (“<strong>Trial Periods</strong>“) for
                            further information.
                        </li>
                        <li>You can cancel a subscription service at any time during the subscription period via the
                            subscription settings in your iTunes account (open your device settings and tap
                            iTunes &amp; App Store &gt; Apple ID &gt; View Apple ID &gt; enter the
                            password &gt; Subscriptions &gt; Assembly &gt; Cancel Subscription button at the
                            bottom).&nbsp;The cancellation will take effect after the last day in the relevant
                            subscription period. See above in paragraph 4 (“<strong>In-App
                                Purchases &amp; Cancellation Rights</strong>“) for further information.&nbsp;If you
                            want to know more about this, please go to&nbsp;<a
                                href="http://support.apple.com/kb/ht4098">http://support.apple.com/kb/ht4098</a>
                        </li>
                        <li>You can switch subscription plan anytime. Just open your device settings and tap
                            iTunes &amp; App Store &gt; Apple ID &gt; View Apple ID &gt; enter the
                            password &gt; Subscriptions &gt; AppName &gt; select the subscription plan you are after.
                        </li>
                        <li>Any unused portion of a free trial period will be forfeited when the user purchases our
                            subscription.
                        </li>
                    </ul>


                    <p><strong>6. Trial Periods.</strong></p>


                    <ul>
                        <li>Certain of our subscription services on the Apple App Store may from time to time be offered
                            for a fixed period of time on a free-trial basis.
                        </li>
                        <li>You are free to cancel a free-trial subscription at any time via the subscription setting in
                            your iTunes account.
                        </li>
                        <li>Weekly free trial automatically converts to a paid weekly subscription unless auto-renew is
                            turned off at least 24-hours before the end of the trial period. From that point onwards,
                            subscription automatically renews unless auto-renew is turned off at least 24-hours before
                            the end of the current period.
                        </li>
                        <li><strong>Please note: your free-trial subscription will automatically renew as a paid
                            subscription unless (a) auto-renew is turned off at least 24 hours before the end of the
                            free-trial subscription period (Apple).</strong></li>
                    </ul>


                    <h3 id="e-publisher-s-app-functionality">E. PUBLISHER’S APP FUNCTIONALITY:</h3>


                    <p>Publisher’s Apps allow you to enjoy various features, functionalities and services, which may
                        change from time to time (collectively, the “Publisher’s App Functions”). The Publisher’s App
                        Functions are provided by Publisher and third party suppliers who offer content and/or services
                        in conjunction with or through the Publisher’s Apps (the “Third Party Partners”). Publisher’s
                        App Functions may provide you with location data for basic navigational purposes only and are
                        not intended to be relied upon in situations where precise location information is needed or
                        where erroneous, inaccurate or incomplete location data may lead to death, personal injury,
                        property or environmental damage. Neither Publisher, nor any of its Third Party Partners,
                        guarantee the availability, accuracy, completeness, reliability, or timeliness of location data
                        displayed by any Publisher’s App.</p>


                    <h3 id="f-third-party-partners">F. THIRD-PARTY PARTNERS:</h3>


                    <p><strong>1. Third-Party Services and Content.</strong>&nbsp;The Publisher’s Apps may integrate, be
                        integrated into, bundled, or be provided in connection with third-party services, advertising,
                        feeds, and/or content. If you are installing a Publisher’s App that includes third party
                        services and third party content, such services and content are subject to such third party’s
                        terms of services and privacy policies, which may be found on the relevant Third Party Partner’s
                        website. Publisher’s Apps may provide access or links to Third Party Partner websites or
                        resources. Publisher has no control over such websites and resources, and you acknowledge and
                        agree that Publisher is not responsible for the availability of such external websites or
                        resources, and does not endorse nor is responsible or liable for any content, advertising,
                        products, or other materials on or available from such websites or resources. You further
                        acknowledge and agree that Publisher shall not be responsible or liable, directly or indirectly,
                        for any damage or loss caused or alleged to be caused by or in connection with use of or
                        reliance on any such Content (as defined below), goods or services available on or through any
                        such website or resource. Publisher will not be a party to or in any way be responsible for
                        monitoring any transaction between you and Third Party Partners.<br></br><strong>2. Access to
                            Third-Party Services and Content through Publisher’s Apps.</strong>&nbsp;All services,
                        advertising, feeds and content, including without limitation, all data, links, articles,
                        graphic or video messages and all information, text, software, music, sound, graphics or
                        other materials (“Content”) made available or accessible through an Publisher’s App, whether
                        publicly available or privately transmitted, is the sole responsibility of the entity or
                        person from whom it originated. You hereby acknowledge and agree that by using an
                        Publisher’s App you may be exposed to Content that may be offensive, indecent or
                        objectionable in your community. You agree to accept all risks associated with the use of
                        any Content, including any reliance on the accuracy or completeness of such Content. Under
                        no circumstances will Publisher be liable in any way for any Content created by or
                        originating with entities other than Publisher, including, but not limited to, any errors or
                        omissions in any such Content, or for loss or damage of any kind incurred as a result of the
                        transmission or posting of such Content by means of a Publisher App.</p>


                    <h3 id="g-security">G. SECURITY:</h3>


                    <p>Publisher’s Apps, like other consumer technologies, may not be 100% secure. By accepting this
                        EULA you acknowledge and accept that the Publisher’s Apps and any information you download or
                        offer to share by means of a Publisher App, may be exposed to unauthorized access, interception,
                        corruption, damage or misuse, and cannot be regarded as 100% secure. You accept all
                        responsibility for such security risks and any damage resulting therefrom.</p>


                    <h3 id="h-registration-passwords">H. REGISTRATION/PASSWORDS:</h3>


                    <p><strong>1. Registration.</strong>&nbsp;Most Publisher’s Apps will not require a registration:
                        however, some Publisher’s Apps may permit or require you to create an account to participate or
                        access additional features or functionalities (“Registration”). If such Registration is
                        required, it will be made known to you when you attempt to participate or access such additional
                        features or functionalities. Any registration required by a Third Party Partner is not governed
                        by this EULA and you should refer to the relevant Third Party Partner’s website for their
                        policies.<br></br><strong>2. Passwords.</strong>&nbsp;You are the sole and exclusive guardian of
                        any
                        password and ID combination issued or chosen by to you. Maintaining the confidentiality and
                        security of your password(s) and ID(s) is solely your responsibility. You are fully
                        responsible for all transactions undertaken by means of any account opened, held, accessed
                        or used via your password and ID. You shall notify us immediately and confirm in writing any
                        unauthorized use of accounts or any breach of security, including without limitation any
                        loss, theft or unauthorized use of your password(s), and/or ID(s) or any related account. If
                        we have reasonable grounds to suspect that the security of your password and/or ID has been
                        compromised, we may suspend or terminate your account, refuse any and all current or future
                        use of the services, and pursue any appropriate legal remedies. We shall not be responsible
                        for any losses incurred in connection with any misuse of any password or ID.<br></br><strong>3.
                            Provided Information.</strong>&nbsp;If you provide any information in connection with a
                        Registration, you must provide and maintain accurate, complete and current information.
                        If we have reasonable grounds to suspect that your information is inaccurate, not
                        current or not complete, we may suspend or terminate your use of the Publisher’s App,
                        and pursue any appropriate legal remedies. You agree that we shall have the right to use
                        the information you provide to us for the purposes described in this EULA and in
                        furtherance of your use of the Publisher’s App services, in accordance with the Privacy
                        Policy located here.</p>


                    <h3 id="i-uninstall-removal-of-an-publisher-s-app">I. UNINSTALL/REMOVAL OF AN PUBLISHER’S APP:</h3>


                    <p>Uninstallation and removal methods vary depending on your device. To uninstall and remove the
                        Publisher’s Apps, please use the application manager provided with your device or consult your
                        device manual for reference.</p>


                    <h3 id="j-consent-to-use-of-data">J. CONSENT TO USE OF DATA:</h3>


                    <p>You agree that we may collect and use technical data and related information, including but not
                        limited to technical information about your device, system and application software, and
                        peripherals, that is gathered periodically to facilitate the provision of software updates,
                        product support and other services to you (if any) related to the Publisher’s Apps. We may use
                        this information in accordance with the Privacy Policy located here.</p>


                    <h3 id="k-intellectual-property">K. INTELLECTUAL PROPERTY:</h3>


                    <p>The Publisher’s Apps, including all design, text, images, photographs, illustrations,
                        audio-clips, video-clips, artwork, graphic material, code, content, protocols, software, and
                        documentation provided to you by Publisher are Publisher’s property or the property of
                        Publisher’s licensors, and are protected by U.S. and international copyright, trademarks,
                        patents and other proprietary rights and laws relating to Intellectual Property Rights.
                        “Intellectual Property Rights” means, collectively, rights under patent, trademark, copyright
                        and trade secret laws, and any other intellectual property or proprietary rights recognized in
                        any country or jurisdiction worldwide, including, without limitation, moral or similar rights.
                        You may not delete, alter, or remove any copyright, trademark, or other proprietary rights
                        notice we or Third Party Partners have placed on or within the Publisher’s Apps. All rights not
                        expressly granted hereunder are expressly reserved to Publisher and its licensors.<br></br>The
                        Publisher’s names, logos and affiliated properties, are the exclusive property of Publisher
                        or its affiliates. All other trademarks appearing on any Publisher’s App are trademarks of
                        their respective owners, and the use of such trademarks shall inure to the benefit of the
                        trademark owner. Our partners or service providers may also have additional proprietary
                        rights in the content which they make available through a Publisher App. The trade names,
                        trademarks and service marks owned by us, whether registered or unregistered, may not be
                        used in connection with any product or service that is not ours, in any manner that is
                        likely to cause confusion. Nothing contained in herein should be construed as granting, by
                        implication, estoppel or otherwise, any license or right to use any of our trade names,
                        trademarks or service marks without our express prior written consent.</p>


                    <h3 id="l-copyright-submissions">L. COPYRIGHT/SUBMISSIONS:</h3>


                    <p><strong>1. You are solely responsible for any Content you contribute, submit or display on or
                        through your use of the Publisher’s App(s).</strong>&nbsp;It is your obligation to ensure that
                        such Content, including photos, text, video and music files, does not violate any copyright or
                        other Intellectual Property Rights. You must either own or have a license to use any Content
                        that you contribute, submit or display.<br></br><strong>2. Publisher respects and expects its
                            users
                            to respect the rights of copyright holders.</strong>&nbsp;On notice, Publisher will act
                        appropriately to remove content that infringes the copyright rights of others. Publisher
                        reserves the right to disable the access to Publisher’s Apps or other services by anyone who
                        uses them to repeatedly infringe the Intellectual Property Rights of others.<br></br><strong>3.
                            Objectionable Content.</strong>&nbsp;Publisher may also act to remove Objectionable
                        Content. The decision to remove Objectionable Content shall be made at Publisher’s sole
                        discretion. “Objectionable Content” includes, but is not limited to:<br></br>– Content that
                        is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, or
                        libelous,<br></br>– Content that is hateful, or advocates hate crimes, harm or violence
                        against a person or group,<br></br>– Content that may harm minors in any way;<br></br>–
                        Content that has the goal or effect of “stalking” or otherwise harassing
                        another<br></br>– Private information about any individual such as phone numbers,
                        addresses, Social Security numbers or any other information that is
                        invasive of another’s privacy;<br></br>– Content that is vulgar, offensive,
                        obscene or pornographic,<br></br>– Unsolicited or unauthorized
                        advertising, promotional materials, “junk mail,” “spam,” “chain
                        letters,” “pyramid schemes,” or any other form of
                        solicitation;<br></br>– Material that contains software viruses or
                        any other computer code, files or programs designed to
                        interrupt, destroy or limit the functionality of any
                        computer software or hardware or telecommunications
                        equipment.<br></br><strong>4. Content Screening and
                            Disclosure.</strong>&nbsp;We do not, and cannot,
                        pre-screen or monitor all Content. However, our
                        representatives may monitor Content submission through
                        the Publisher’s Apps, and you hereby provide your
                        irrevocable consent to such monitoring. You acknowledge
                        and agree that you have no expectation of privacy
                        concerning the submission of any Content. We have the
                        right, but not the obligation, in our sole discretion to
                        edit, refuse to post, or remove any Content.<br></br>We may
                        access, preserve or disclose any of your information
                        or Content (including without limitation chat text)
                        if we are required to do so by law, or if we believe
                        in good faith that it is reasonably necessary to (i)
                        respond to claims asserted against us or to comply
                        with legal process (for example, subpoenas or
                        warrants), including those issued by courts having
                        jurisdiction over us or you; (ii) enforce or
                        administer our agreements with users, such as this
                        EULA; (iii) for fraud prevention, risk assessment,
                        investigation, customer support, providing the app
                        services or engineering support; (iv) protect the
                        rights, property or safety of Publisher, its users,
                        or members of the public or (v) to report a crime or
                        other offensive behaviour.<br></br><strong>5. Ownership
                            of Content You Submit.</strong>&nbsp;Unless
                        otherwise set forth at the point of submission,
                        you retain ownership of all rights in any
                        Content that you submit, through your use of the
                        Publisher’s Apps. However, you grant us
                        permission to use such Content in any way we see
                        fit, for instance for the purposes of promotion
                        of the Publisher’s Apps. If, at our request, you
                        send submissions (such as contest submissions,
                        polling questions) or you send us creative
                        suggestions, ideas, notes, drawings, or other
                        information (collectively, the “Submissions”),
                        such Submissions shall be deemed, and shall
                        remain, the property of Publisher. None of the
                        Submissions shall be subject to any obligation
                        of confidence on the part of Publisher, and
                        Publisher shall not be liable for any use or
                        disclosure of any Submissions. Without
                        limitation of the foregoing, Publisher shall
                        exclusively own all now known or hereafter
                        existing rights to the Submissions of every kind
                        and nature throughout the universe and shall be
                        entitled to unrestricted use of the Submissions
                        for any purpose whatsoever, commercial or
                        otherwise, without compensation to the provider
                        of the Submissions. You hereby assign to
                        Publisher all right, title and interest in and
                        to the Submissions and you hereby waive any
                        moral rights (and any rights of the same or
                        similar effect anywhere in the world existing
                        now or in the future created) relating to the
                        Submissions in favour of Publisher and its
                        assignees, licensees and designees.<br></br><strong>6.
                            Repeat Infringer
                            Policy.</strong>&nbsp;Publisher may
                        terminate a user’s access to the Publisher’s
                        App(s) if, under appropriate circumstances,
                        the user is determined to be a repeat
                        infringer.<br></br><strong>7. No Intended Third
                            Party
                            Beneficiaries.</strong>&nbsp;Except as
                        otherwise set forth herein, no third
                        party is an intended beneficiary of this
                        EULA.<br></br><strong>8. If you are a
                            copyright owner or an agent thereof
                            and believe, in good faith, that any
                            materials provided on the Service
                            infringe upon your copyrights, you
                            may submit a notification pursuant
                            to the Digital Millennium Copyright
                            Act (see 17 U.S.C 512) (“DMCA”) by
                            sending the following information in
                            writing to company’s designated
                            copyright agent using&nbsp;Contact
                                Form:</strong></p>


                    <ul>
                        <li>The date of your notification</li>
                        <li>A physical or electronic signature of a person authorized to act on behalf of the owner of
                            an exclusive right that is allegedly infringed
                        </li>
                        <li>A description of the copyrighted work claimed to have been infringed, or, if multiple
                            copyrighted works at a single online site are covered by a single notification, a
                            representative list of such works at that site
                        </li>
                        <li>A description of the material that is claimed to be infringing or to be the subject of
                            infringing activity and information sufficient to enable us to locate such work
                        </li>
                        <li>Information reasonably sufficient to permit the service provider to contact you, such as an
                            address, telephone number, and/or email address
                        </li>
                        <li>A statement that you have a good faith belief that use of the material in the manner
                            complained of is not authorized by the copyright owner, its agent, or the law and
                        </li>
                        <li>A statement that the information in the notification is accurate, and under penalty of
                            perjury, that you are authorized to act on behalf of the owner of an exclusive right that is
                            allegedly infringed.
                        </li>
                    </ul>


                    <h3 id="m-termination">M. TERMINATION:</h3>


                    <p>Your rights under this EULA will terminate immediately and automatically without any notice from
                        Publisher if you fail to comply with any of the terms and conditions of this EULA. You
                        understand that Publisher, in its sole discretion, may modify or discontinue or suspend your
                        right to access any of our services or use of any Publisher’s Apps at any time. Further,
                        Publisher, with or without any reason, may at any time suspend or terminate any license
                        hereunder and disable the Publisher’s Apps or any of its component features. You agree that
                        Publisher shall not be liable to you or any third-party for any termination or disabling of the
                        Publisher’s Apps. Promptly upon expiration or termination of this EULA, you must cease all use
                        of the Publisher’s Apps and destroy all copies of Publisher’s Apps in your possession or
                        control. Termination will not limit any of Publisher’s other rights or remedies at law or in
                        equity. Sections J-S, and any Supplemental Terms of this EULA shall survive termination or
                        expiration of this EULA for any reason.</p>


                    <h3 id="n-disclaimer-of-warranty">N. DISCLAIMER OF WARRANTY:</h3>


                    <p>TO THE EXTENT THIS IS PERMITTED BY APPLICABLE LAW, ALL PUBLISHER’S APPS ARE PROVIDED ON AN “AS
                        IS,” “WITH ALL FAULTS,” AND “AS AVAILABLE” BASIS, AND YOU USE THEM AT YOUR SOLE RISK. SUBJECT TO
                        APPLICABLE LAW, PUBLISHER, ON BEHALF OF ITSELF, AND ITS AFFILIATES, LICENSORS, DISTRIBUTORS,
                        VENDORS, AGENTS AND SUPPLIERS, EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES OF ANY KIND, WHETHER
                        EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND ANY OTHER WARRANTY ARISING UNDER THE
                        SALE OF GOODS ACTS 1893 AND 1980, USAGE OF TRADE, COURSE OF CONDUCT OR OTHERWISE. WITHOUT
                        LIMITATION, PUBLISHER MAKES NO WARRANTY THAT THE PUBLISHER’S APPS WILL MEET YOUR REQUIREMENTS,
                        THAT THEY WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, THAT THE RESULTS OBTAINED FROM
                        THE USE OF THE PUBLISHER PRODUCTS WILL BE ACCURATE OR RELIABLE, OR THAT THE QUALITY OF THE
                        PUBLISHER’S APPS WILL MEET YOUR EXPECTATIONS. PUBLISHER ASSUMES NO LIABILITY OR RESPONSIBILITY
                        FOR ANY PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR
                        PUBLISHER’S APPS; ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
                        PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN; ANY INTERRUPTION OR CESSATION
                        OF TRANSMISSION TO OR FROM PUBLISHER’S APPS OR SERVERS; ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
                        LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH PUBLISHER’S APPS BY ANY THIRD PARTY; OR ANY ERRORS
                        OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
                        USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE PUBLISHER’S
                        APPS.<br></br>THE ENTIRE RISK ARISING OUT OF USE OR PERFORMANCE OF THE PUBLISHER’S APPS REMAINS
                        SOLELY WITH YOU.<br></br>PUBLISHER EXPRESSLY DISCLAIMS ALL WARRANTIES RELATING TO PRODUCTS
                        AND/OR
                        SERVICES PROVIDED BY THIRD PARTY PARTNERS.<br></br>SOME JURISDICTIONS DO NOT ALLOW THE
                        DISCLAIMER OF IMPLIED WARRANTIES. IN SUCH JURISDICTIONS, THE FOREGOING DISCLAIMERS
                        MAY NOT APPLY TO YOU INSOFAR AS THEY RELATE TO IMPLIED WARRANTIES.<br></br>THIS
                        DISCLAIMER OF WARRANTY CONSTITUTES AN ESSENTIAL PART OF THIS AGREEMENT.</p>


                    <h3 id="o-limitation-of-liability">O. LIMITATION OF LIABILITY:</h3>


                    <p>TO THE EXTENT PERMITTED BY APPLICABLE LAWS, YOU EXPRESSLY UNDERSTAND AND AGREE THAT PUBLISHER
                        SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
                        DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
                        INTANGIBLE LOSSES (EVEN IF PUBLISHER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
                        RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE PUBLISHER’S APPS; (II) UNAUTHORIZED
                        ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (III) STATEMENTS OR CONDUCT OF ANY THIRD
                        PARTY; OR (IV) ANY OTHER MATTER RELATING TO THE PUBLISHER’S APPS. IN NO EVENT SHALL PUBLISHER’S
                        TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT
                        (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE) EXCEED THE AMOUNT PAID BY YOU, IF
                        ANY, FOR ACCESSING THE PUBLISHER’S APP. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE
                        STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.<br></br>IF ANY OF THE EXCLUSIONS SET FORTH IN THIS
                        SECTION IS DETERMINED BY A COURT OF COMPETENT JURISDICTION TO BE UNENFORCEABLE, THEN ALL
                        SUCH EXPRESS, IMPLIED AND STATUTORY WARRANTIES SHALL BE LIMITED IN DURATION FOR A PERIOD OF
                        THIRTY (30) DAYS AFTER THE DATE ON WHICH YOU FIRST ACCESS THE PUBLISHER’S APP, AND NO
                        WARRANTIES SHALL APPLY AFTER SUCH PERIOD.</p>


                    <h3 id="p-indemnification">P. INDEMNIFICATION:</h3>


                    <p>YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS PUBLISHER, ITS PARENTS, AFFILIATE AND SUBSIDIARY
                        COMPANIES, OFFICERS, DIRECTORS, EMPLOYEES, CONSULTANTS AND AGENTS FROM ANY AND ALL THIRD PARTY
                        CLAIMS, LIABILITY, DAMAGES AND/OR COSTS (INCLUDING, BUT NOT LIMITED TO, ATTORNEYS’ FEES) ARISING
                        FROM YOUR USE OF THE PUBLISHER’S APPS, YOUR VIOLATION OF THE EULA OR YOUR INFRINGEMENT, OR
                        INFRINGEMENT BY ANY OTHER USER OF YOUR ACCOUNT, OF ANY INTELLECTUAL PROPERTY OR OTHER RIGHT OF
                        ANY PERSON OR ENTITY. YOU AGREE TO IMMEDIATELY NOTIFY PUBLISHER OF ANY UNAUTHORIZED USE OF YOUR
                        ACCOUNT OR ANY OTHER BREACH OF SECURITY KNOWN TO YOU.</p>


                    <h3 id="q-export-controls">Q. EXPORT CONTROLS:</h3>


                    <p>The Publisher’s Apps and the underlying information and technology are subject to US and
                        international laws, restrictions and regulations that may govern the import, export, downloading
                        and use of the Apps. You agree to comply with these laws, restrictions and regulations when
                        downloading or using the apps.</p>


                    <h3 id="r-notice-to-us-government-end-users">R. NOTICE TO US GOVERNMENT END USERS:</h3>


                    <p>Any Publisher’s Apps installed for or on behalf of the United States of America, its agencies
                        and/or instrumentalities (“U.S. Government”), is provided with Restricted Rights as “commercial
                        Items,” as that terms is defined at 48 C.F.R. §2.101, consisting of “Commercial Computer
                        Software” and “Commercial Computer Software Documentation,” as such terms are used in 48 C.F.R.
                        §12.212 or 48 C.F.R. §227.7202, as applicable. Pursuant to Federal Acquisition Regulation 12.212
                        (48 C.F.R. §12.212), the U.S. Government shall have only those rights specified in the license
                        contained herein. The U.S. Government shall not be entitled to (i) technical information that is
                        not customarily provided to the public or to (ii) use, modify, reproduce, release, perform,
                        display, or disclose commercial computer software or commercial computer software documentation
                        except as specified herein. Use, duplication, or disclosure by the U.S. Government is subject to
                        restrictions as set forth in subparagraph (c)(1)(ii) of the Rights in Technical Data and
                        Computer Software clause at DFARS 252.227-7013 or subparagraphs (c)(1) and (2) of the Commercial
                        Computer Software – Restricted Rights at 48 C.F.R. 52.227-19, as applicable.</p>


                    <h3 id="s-jurisdictional-issues-and-other-miscellaneous-terms">S. JURISDICTIONAL ISSUES AND OTHER
                        MISCELLANEOUS TERMS:</h3>


                    <p>Publisher does not represent or warrant that the Publisher’s Apps or any part thereof is
                        appropriate or available for use in any particular jurisdiction. We may limit the availability
                        of the Publisher’s Apps, in whole or in part, to any person, geographic area or jurisdiction we
                        choose, at any time and in our sole discretion. The laws of Publisher’s country, without regard
                        to conflict of laws principles, shall govern all matters relating to or arising from this EULA,
                        and the use (or inability to use) the Publisher’s Apps. You hereby submit to the exclusive
                        jurisdiction and venue of the appropriate courts of Ireland, with respect to all matters arising
                        out of or relating to this EULA.<br></br>No failure or delay by Publisher in exercising any
                        right,
                        power or privilege under this EULA will operate as a waiver thereof, nor will any single or
                        partial exercise of any right, power or privilege preclude any other or further exercise
                        thereof or the exercise of any other right, power, or privilege under this EULA. If any
                        provision of this EULA shall be found unlawful, void, or for any reason unenforceable, then
                        that provision shall be deemed severable from these terms and shall not affect the validity
                        and enforceability of any remaining provisions.<br></br>YOU AGREE THAT ANY CAUSE OF ACTION
                        ARISING OUT OF OR RELATED TO THE PUBLISHER’S APPS MUST COMMENCE WITHIN THREE (3) YEARS
                        AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
                        BARRED.</p>


                    <h3 id="t-supplemental-terms">T. SUPPLEMENTAL TERMS</h3>


                    <p>To the extent permitted by applicable law, the following supplemental terms shall apply:<br></br>Apple
                        App Store: By accessing the Publisher’s App through a device made by Apple, Inc. (“Apple”), you
                        specifically acknowledge and agree that:<br></br>1. This EULA is between Publisher and you;
                        Apple is
                        not a party to this EULA.<br></br>2. The license granted to you hereunder is limited to a
                        personal, limited, non-exclusive, non-transferable right to install the Publisher’s App
                        on the Apple device(s) authorized by Apple that you own or control for personal,
                        non-commercial use, subject to the Usage Rules set forth in Apple’s App Store Terms of
                        Service.<br></br>3. Apple is not responsible for Publisher’s App or the content thereof and
                        has no obligation whatsoever to furnish any maintenance or support services with
                        respect to the Publisher’s App.<br></br>4. In the event of any failure of the Publisher’s
                        App to conform to any applicable warranty, you may notify Apple, and Apple will
                        refund the purchase price for the Publisher’s App, if any, to you. To the
                        maximum extent permitted by applicable law, Apple will have no other warranty
                        obligation whatsoever with respect to the Publisher’s App.<br></br>5. Apple is not
                        responsible for addressing any claims by you or a third party relating to
                        the Publisher’s App or your possession or use of the Publisher’s App,
                        including without limitation (a) product liability claims; (b) any claim
                        that the Publisher’s App fails to conform to any applicable legal or
                        regulatory requirement; and (c) claims arising under consumer protection or
                        similar legislation.<br></br>6. In the event of any third party claim that the
                        Publisher’s App or your possession and use of the Publisher’s App
                        infringes such third party’s intellectual property rights, Apple is not
                        responsible for the investigation, defense, settlement or discharge of
                        such intellectual property infringement claim.<br></br>7. You represent and
                        warrant that (a) you are not located in a country that is subject to
                        a U.S. Government embargo, or that has been designated by the U.S.
                        Government as a “terrorist supporting” country; and (b) you are not
                        listed on any U.S. Government list of prohibited or restricted
                        parties.<br></br>8. Apple and its subsidiaries are third party
                        beneficiaries of this EULA and upon your acceptance of the terms
                        and conditions of this EULA, Apple will have the right (and will
                        be deemed to have accepted the right) to enforce this EULA
                        against you as a third party beneficiary hereof.<br></br>9. Publisher
                        expressly authorizes use of the Publisher’s Apps by multiple
                        users through the Family Sharing or any similar
                        functionality provided by Apple.</p>
                </div>
            </div>
            <FooterSection
                privacy={page?.sixth_section_policy}
                terms={page?.sixth_section_terms}
                copyright={page?.sixth_section_copyright}
            />
        </div>
    );
};

export default TermsOfUsePage;