import { useEffect, useContext } from 'react'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as AppStoreImage } from '../../assets/app-store.svg'
import { ReactComponent as GooglePlayImage } from '../../assets/google-play.svg'
import { logEvent } from '../../services/firebase'
import { APPSTORE_APP_LINK, PLAYMARKET_APP_LINK } from '../../config'
import Context from '../../state/Context'
import {
  ChouseAppMain,
  Markets,
  MarketPlaceOne,
  MarketPlaceTwo,
  ChouseAppContent,
  Subtitle,
  ChouseAppLogo,
} from './styles'

const ChooseApp = () => {
  const { platform, locales } = useContext(Context)

  const { chooseApp: page } = locales

  useEffect(() => {
    logEvent('hc_landing_2_screen')
  }, [])

  const onAppStoreClick = () => {
    logEvent('hc_landing_2_screen_apple_store_tap', {
      platform,
    })

    window.location.href = APPSTORE_APP_LINK
  }

  const onPlaymarketClick = () => {
    logEvent('hc_landing_2_screen_google_store_tap', {
      platform,
    })

    window.location.href = PLAYMARKET_APP_LINK
  }

  return (
    <>
      <ChouseAppMain>
        <ChouseAppLogo>
          <Logo />
        </ChouseAppLogo>
        <Subtitle>{page?.title}</Subtitle>

        <ChouseAppContent>
          <img src={page?.img} alt="" />
        </ChouseAppContent>

        <Markets>
          <MarketPlaceOne onClick={onAppStoreClick}>
            <AppStoreImage />
          </MarketPlaceOne>
          
          <MarketPlaceTwo onClick={onPlaymarketClick}>
            <GooglePlayImage />
          </MarketPlaceTwo>
        </Markets>
      </ChouseAppMain>
    </>
  )
}

export default ChooseApp
