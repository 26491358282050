import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent as logEventFb, setUserProperties } from 'firebase/analytics'

const app = initializeApp({
  apiKey: "AIzaSyAIU1EjYK280YKnlx6kyIwx3S5eVwyilxI",
  authDomain: "happy-canvas-landing-1.firebaseapp.com",
  projectId: "happy-canvas-landing-1",
  storageBucket: "happy-canvas-landing-1.appspot.com",
  messagingSenderId: "688861007359",
  appId: "1:688861007359:web:9e031b3151f9466d2e6bca",
  measurementId: "G-Q711M8DPEE"
})

const analytics = getAnalytics(app)

export const setUserData = () => {
  const urlParams = new URLSearchParams(window.location.search)

  const props = {}

  for (const [key, value] of urlParams.entries()) {
    props[key] = value;
  }

  if (process.env.NODE_ENV === 'development') {
    console.log('setUserProperties', props)
  } else {
    setUserProperties(analytics, props)
  }
}

export const logEvent = (name, props) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('logEvent', { name, props })
  } else {
    logEventFb(analytics, name, props)
  }
}
