export const APPS_FLYER_ONELINK = 'https://happycanvas.onelink.me/tsNu'

export const APPSTORE_APP_LINK = 'https://apps.apple.com/us/app/happy-canvas-color-by-number/id1500862622'

export const PLAYMARKET_APP_LINK = 'https://play.google.com/store/apps/details?id=com.coloring.lwapps'

export const INSTAGRAM_LINK = 'https://www.instagram.com/happy_canvas_official/'

export const FACEBOOK_LINK = 'https://www.facebook.com/happycanvascolorbynumber/'

export const PRIVACY_POLICY_LINK = '/privacy-policy'

export const TERMS_OF_USE_LINK = '/terms-of-use'
