import styled from 'styled-components'

// BENEFITS
export const BenefitsSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2.5rem 0;

  @media screen and (min-width: 1024px) {
    padding: 3.375rem 0;
    padding-bottom: 3.938rem;
  }
`

export const SectionTitle = styled.h2`
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 2rem;
  text-align: center;
  color: #222762;
  margin-bottom: 1rem;
  padding: 0 1.25rem;

  @media screen and (min-width: 768px) {
    font-size: 2.125rem;
    margin-bottom: 1.875rem;
  }

  @media screen and (min-width: 1024px) {
    font-size: 2.625rem;
    margin-bottom: 5.875rem;
  }
`

export const BenefitItems = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
  }
`
// BENEFITS END

// COROUSEL
export const CorouselSection = styled.div`
  padding-top: 2.563rem;
  padding-bottom: 4.813rem;
  background: #F8FAFF;

  @media screen and (min-width: 768px) {
    padding-top: 2.688rem;
  }

  @media screen and (min-width: 1024px) {
    padding-top: 3.375rem;
  }
`

export const CorouselSectionTitle = styled(SectionTitle)`
  @media screen and (min-width: 768px) {
    margin-bottom: 1rem;
  }
`

export const CorouselSectionSubtitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.188rem;
  margin-bottom: 0.625rem;
  text-align: center;
  color: #0085FF;
  text-transform: uppercase;
  padding-left: 1rem;
  padding-right: 1rem;

  @media screen and (min-width: 768px) {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`

export const CorouselSectionText = styled.div`
  font-size: 1rem;
  line-height: 130%;
  text-align: center;
  color: #343434;
  margin-bottom: 0.85rem;
  max-width: 53.125rem;
  margin: 0 auto;
  margin-bottom: 0.95rem;
  padding-left: 0.938rem;
  padding-right: 0.938rem;

  @media screen and (min-width: 768px) {
    font-size: 1.125rem;
  }
`
// COROUSEL END